import React from 'react'
import { useState, useEffect } from 'react'
import InputField from '../form/InputField'
import FormWrapper from '../form/FormWrapper'
import PropTypes from 'prop-types'
import useFormState from '../form/hooks/useFormState'
import TailwindScreens from '../../helpers/tailwind'
import { IconChevronLeft } from '../Icons'
import isEmail from '../form/validators/isEmail'
import isPhone from '../form/validators/isPhone'

export const EZShipBoxScroll = () => {
  if (window && window.document && window.document.getElementById('ezship-box')) {
    window.document.getElementById('ezship-box').scrollIntoView()
  }
}

const EZShipBoxBackdrop = ({ open, toggleForm }) => {
  const [showBackdrop, setShowBackdrop] = useState(false)

  useEffect(() => {
    setShowBackdrop(open)
    if (!open) return
    // check if screen size is md or up
    if (!window.matchMedia(TailwindScreens.md).matches) return
    EZShipBoxScroll()
    const timeout = setTimeout(() => {
      setShowBackdrop(false)
      toggleForm()
    }, 2000)
    return () => clearTimeout(timeout)
  }, [open])

  if (!showBackdrop) return null
  return (
    <>
      <div
        onClick={toggleForm}
        className={
          'hidden fixed inset-0 md:block top-0 left-0 w-full h-full z-10 bg-black opacity-20 delay-1000 duration-200 ease-in-out motion-safe:animate-fade-out-20'
        }
      />
      <div
        onClick={toggleForm}
        className={
          'hidden backdrop-blur-[2px] fixed inset-0 md:block top-0 left-0 w-full h-full z-10 delay-1000 duration-200 ease-in-out motion-safe:animate-fade-out'
        }
      />
    </>
  )
}

const EZShipBoxSideForm = ({ className, open, toggleForm }) => {
  const [form, , updateForm] = useFormState({
    name: '',
    phone: '',
    brand: '',
    email: '',
    description: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  })

  const [isValid, setIsValid] = useState(false)

  const [step, setStep] = useState(1)

  useEffect(() => {
    const requiredFields = ['name', 'phone', 'email', 'address', 'city', 'state', 'zip']
    const allFieldsValid = requiredFields.every(field => form[field] && form[field].trim() !== '')
    setIsValid(allFieldsValid)
  }, [form])

  const handleNextClick = e => {
    if (step === 1) {
      setStep(2)
      e.preventDefault()
    }
  }
  const handlePrevClick = () => {
    if (step === 2) {
      setStep(1)
    } else {
      // TODO: Greg Handle form submission here
    }
  }

  return (
    <aside
      id={'ezship-box'}
      className={
        'relative order-1 z-30  mx-auto w-full flex-grow lg:order-2 lg:max-w-[350px] xl:block px-12 lg:px-2 ' +
        (open ? 'lg:custom-bounce ' : '') +
        className
      }
    >
      <div className="sticky top-5 mb-5 bg-white rounded-md">
        <div className=" my-5 w-full mx-auto lg:w-full ">
          <div className=" flex w-full flex-col items-center justify-center rounded-md border border-gray-200 shadow-md duration-200 hover:border-gray-400">
            <button onClick={toggleForm} className={'w-full'}>
              <h3
                className={
                  (open ? 'rounded-t-md ' : 'rounded-t-md rounded-b-md lg:rounded-b-none ') +
                  ' bg-red-700 py-3 text-xl text-center font-bold text-white'
                }
              >
                EZ SHIP BOX REQUEST
              </h3>
            </button>
            <div
              className={
                'lg:max-h-max lg:py-4 px-4 duration-200 overflow-hidden ' +
                (open ? 'max-h-[700px] py-4' : 'max-h-0 py-0')
              }
            >
              <FormWrapper
                className={'flex-col md:flex-row'}
                name={'EZ Ship Box Repairs'}
                action={'/thank-you-ez-ship-box/'}
                form={form}
                category={'repair'}
              >
                <h4 className="text-center">
                  Submit a request for an EZ Ship Box to send your item(s) to us for repair. We will
                  contact you with a quote and shipping instructions.
                  Please note, the customer is responsible for "insured return shipping payment" regardless of whether they approve the repair quote.
                </h4>
                {step === 1 && (
                  <div className="flex flex-col space-y-1">
                    <InputField
                      errorMessage=""
                      name={'name'}
                      label={'Name'}
                      form={form}
                      onChange={updateForm}
                      required={true}
                    />
                    <InputField
                      errorMessage=""
                      name={'phone'}
                      label={'Phone'}
                      validator={isPhone}
                      form={form}
                      onChange={updateForm}
                      required={true}
                    />
                    <InputField
                      errorMessage=""
                      name={'email'}
                      label={'E-Mail'}
                      form={form}
                      validator={isEmail}
                      onChange={updateForm}
                      required={true}
                    />
                    <InputField
                      errorMessage=""
                      name={'address'}
                      label={'Address'}
                      form={form}
                      onChange={updateForm}
                      required={true}
                    />
                    <InputField
                      errorMessage=""
                      name={'city'}
                      label={'City'}
                      form={form}
                      onChange={updateForm}
                      required={true}
                    />
                    <div className="flex flex-row gap-4">
                      <InputField
                        errorMessage=""
                        name={'state'}
                        label={'State'}
                        form={form}
                        onChange={updateForm}
                        required={true}
                      />
                      <InputField
                        errorMessage=""
                        name={'zip'}
                        label={'Zip Code'}
                        form={form}
                        onChange={updateForm}
                        required={true}
                      />
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <>
                    <button
                      onClick={handlePrevClick}
                      className="border-red-700 border-2 text-red-700 w-full rounded-md py-2 uppercase my-5 group"
                    >
                      <IconChevronLeft className="translate-y-[.15rem] group-hover:-translate-x-2 duration-300 ease-in-out" />{' '}
                      BACK
                    </button>
                    <InputField
                      errorMessage=""
                      name={'brand'}
                      label={'Brand & Model'}
                      form={form}
                      onChange={updateForm}
                    />
                    <InputField
                      errorMessage=""
                      name={'description'}
                      label={'Description'}
                      type={'textarea'}
                      rows={5}
                      form={form}
                      onChange={updateForm}
                    />
                  </>
                )}

                <button
                  onClick={handleNextClick}
                  className={
                    (isValid ? 'bg-red-700 ' : 'bg-gray-300 ') +
                    ' text-white w-full rounded-md py-2 uppercase mt-5 duration-300'
                  }
                  disabled={!isValid}
                >
                  {step === 1 ? 'Continue' : 'SUBMIT FORM'}
                </button>
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}
EZShipBoxBackdrop.propTypes = {
  open: PropTypes.bool,
  toggleForm: PropTypes.func,
}
EZShipBoxSideForm.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggleForm: PropTypes.func,
}
export { EZShipBoxBackdrop }

export default EZShipBoxSideForm
